import React from 'react';
import Helmet  from 'react-helmet'

export class BasePage extends React.Component<{title?: string, className?: string, children: React.ReactNode}, {}> {

    constructor(props) {
        super(props);
    }

    public render() {
        return <div key="BASE PAGE" className={this.props.className}>
            <Helmet
                key="helm"
                title={`lordvirr.com ${this.props.title ? `(${this.props.title})` : ""}`}
                meta={[
                    { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"},
                    { name: 'description', content: 'Lordvirr, video creator.' },
                    { name: 'keywords', content: 'Hmwrk, lordvirr, video, tiktok, frog, portal' },
                ]}
                > 
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='true' />
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap" rel="stylesheet"></link>
            </Helmet>
            {this.props.children}
        </div>
    }
}