import React, { Component } from 'react';

import { BasePage } from '../components/BasePage';
import { ContentCard } from '../components/ContentCard';

import dp from '../../static/dp.jpeg'
import logo from '../images/lordvirr.png'
import hmwrk from '../images/hmwrk logo.png'
import trees from '../images/lordvirr trees.png'

// tslint:disable-next-line:no-var-requires
require("./index.less");

interface HomePageProps extends React.HTMLProps<HTMLDivElement> { }
interface HomePageState extends React.HTMLProps<HTMLDivElement> {
    loading: boolean,
}

export default class HomePage extends React.Component<HomePageProps, HomePageState> {

    constructor(props) {
        super(props);
    }

    public render() {
        return <BasePage key="Main Page" children={[
            <img src={trees} className="background-image" />,
            <div key="SPLIT"  >
                

                {/* <img className='logo-lordvirr' src={logo} /> */}
                <div style={{position:"absolute"}} className="content-column">
                    <ContentCard border={false} children={[
                        <img className='dp' src={dp} />,
                        {type: "Heading1", content: "lordvirr"},
                    ]}/>
                    <div className="content-row buttons">
                        <a className='social-button tiktok' title="" href="https://tiktok.com/@lordvirr"> tiktok </a>
                        <a className='social-button instagram' title="" href="https://instagram.com/lordvirr"> instagram </a>
                        <a className='social-button twitter' title="" href="https://twitter.com/cody_r_helem"> twitter </a>
                        <a className='social-button twitch' title="" href="https://twitch.com/lordvirr"> twitch </a>
                        <a className='social-button youtube' title="" href="https://youtube.com/lordvirr"> youtube </a>
                        <a className='social-button ' title="" href="mailto:hmwrk.contact@gmail.com"> email </a>
                    </div>

                    {/* <img className='logo-lordvirr' src={logo} /> */}
                <a className='link logo-hmwrk'  style={{right: "20px", bottom: "20px"}} href="https://hmwrk.net" > <img src={hmwrk} /> </a>
                </div>
            </div>
        ]} />
    }
}